export default {
    tableFields: [
      {
        name: '__component:badge-column',
        title: '',
        dataClass: 'text-center',
        width: '4%'
      },

      {
        name: 'detail.name',
        title: 'Nombre',
        sortField: 'detail.name',
        width: '24%'
      },
      {
        name: 'detail.model',
        title: 'Modelo',
        sortField: 'detail.model',
        width: '24%'
      },
      {
        name: 'detail.softwareversion',
        title: 'Versión Software',
        sortField: 'detail.softwareversion',
        width: '24%'
      },
      {
        name: '__slot:device',
        title: 'Dispositivo',
        //width: '34%'
    },

    ],
    sortFunctions: {
      'detail.name': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      },
    }
  }
